import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import Layout from "./components/Layout";
import Dashboard from "./pages/Dashboard";
import Account from "./pages/Account";
import Team from "./pages/Team";
import Swap from "./pages/Swap";
import toast from "react-hot-toast";
import StashHouse from "./pages/StashHouse";
import Farms from "./pages/Farms";
import { useEffect } from "react";
import { PulseChain } from "./PulseChain";
import { BSC, useEthers } from "@usedapp/core";

function App() {
  const { switchNetwork, chainId } = useEthers();

  useEffect(() => {
    const asyncFn = async () => {
      await switchNetwork(BSC.chainId);
    };

    if (chainId !== BSC.chainId) {
      asyncFn();
    }
  }, [chainId, switchNetwork]);

  const location = useLocation();
  return (
    <Layout>
      <Routes location={location}>
        <Route path="/" element={<StashHouse />} />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </Layout>
  );
}

export default App;
