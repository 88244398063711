import { useEffect, useState } from "react";
import { useEthers, shortenAddress, useLookupAddress } from "@usedapp/core";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";

const Web3ModalButton = ({ className }) => {
  const { account, activate, deactivate } = useEthers();
  const { ens } = useLookupAddress(account);
  const [activateError, setActivateError] = useState("");
  const { error } = useEthers();
  useEffect(() => {
    if (error) {
      setActivateError(error.message);
    }
  }, [error]);

  const activateProvider = async () => {
    const providerOptions = {
      injected: {
        display: {
          name: "Metamask",
          description: "Connect with the provider in your Browser",
        },
        package: null,
      },
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          bridge: "https://bridge.walletconnect.org",
          infuraId: "d8df2cb7844e4a54ab0a782f608749dd",
        },
      },
    };

    const web3Modal = new Web3Modal({
      providerOptions,
    });
    try {
      const provider = await web3Modal.connect();
      await activate(provider);
      setActivateError("");
    } catch (error) {
      setActivateError(error.message);
    }
  };

  return (
    <>
      {/* {activateError && <div>{activateError}</div>} */}
      {account ? (
        <button onClick={() => deactivate()} className={className}>
          Disconnect{" "}
          <p className="hidden lg:inline">({ens ?? shortenAddress(account)})</p>
        </button>
      ) : (
        <button onClick={activateProvider} className={className}>
          Connect Wallet
        </button>
      )}
    </>
  );
};

export default Web3ModalButton;
