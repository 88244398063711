import { utils } from "ethers";
import { useCall } from "@usedapp/core";
import { Contract } from "@ethersproject/contracts";
import ERC20Interface from "../../contracts/erc20.json";

const useTokenBalance = (tokenAddress, address, decimals = 18) => {
  const { value, error } = useCall(
    address &&
      tokenAddress && {
        contract: new Contract(
          tokenAddress,
          new utils.Interface(ERC20Interface)
        ),
        method: "balanceOf",
        args: [address],
      }
  ) ?? { value: [0] };
  if (error) {
    console.error(error.message);
    return undefined;
  }
  return parseFloat(utils.formatUnits(value?.[0], decimals));
};

export default useTokenBalance;
