import { useLocation } from "react-router-dom";
import classes from "./index.module.css";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { Toaster } from "react-hot-toast";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faDiscord,
  faMedium,
  faTelegram,
} from "@fortawesome/free-brands-svg-icons";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import pic from "../../assets/images/logo.svg";

const Layout = ({ children }) => {
  const location = useLocation();
  let arr = ["/dashboard", "/swap", "/account", "/stake", "/liberate"];
  arr.indexOf(location.pathname);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  return (
    <div className="flex">
      <Toaster position="bottom-center" />
      <div
        className={`${classes.main} ${
          location.pathname == "/stashhouse" && classes.stashhouse
        } relative w-full h-screen`}
      >
        {/* <div className={`${classes.main} relative w-full ml-72 h-screen bg-cover bg-no-repeat`} style={{'background-image': `url('/bg/${arr.indexOf(location.pathname) + 1}.png')`}}> */}
        <div className="w-full flex justify-center items-center flex-col">
          <div className="w-11/12 relative flex flex-col justify-center items-center z-10">
            <Header setIsMenuOpen={setIsMenuOpen} />
            {children}
          </div>
          <div className="bg-darky w-full flex flex-col p-12 justify-center items-center gap-6">
            <a
              href="https://pulsedrip.io"
              target="_blank"
              rel="noreferrer"
              className="flex flex-col items-center"
            >
              <img src={pic} alt="logo" className="w-40 " />
              <span className="text-pinky">Powered by Pulse Drip</span>
            </a>
            <div className="flex gap-4">
              <a
                rel="noreferrer"
                href="https://kitchen-sink.gitbook.io/kitchensink/"
                target="_blank"
                className={`${classes.neon} w-8 h-8 opacity-50 hover:opacity-100 rounded-full text-white border border-pinky flex items-center justify-center transition-all duration-150 cursor-pointer text-sm`}
              >
                <FontAwesomeIcon icon={faBook} />
              </a>
              <a
                rel="noreferrer"
                href="https://twitter.com/Pulsechaindrip"
                target="_blank"
                className={`${classes.neon} w-8 h-8 opacity-50 hover:opacity-100 rounded-full text-white border border-pinky flex items-center justify-center transition-all duration-150 cursor-pointer text-sm`}
              >
                <FontAwesomeIcon icon={faTwitter} />
              </a>
              <a
                rel="noreferrer"
                href="https://t.me/pulsedripnetwork"
                target="_blank"
                className={`${classes.neon} w-8 h-8 opacity-50 hover:opacity-100 rounded-full text-white border border-pinky flex items-center justify-center transition-all duration-150 cursor-pointer`}
              >
                <FontAwesomeIcon icon={faTelegram} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
