import { useState, useEffect, useRef } from "react";
import { toast } from "react-hot-toast";
import Countdown from "react-countdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

import { constants, utils } from "ethers";
import { GARDEN_TOKENS, STASH_HOUSE_ADDRESS } from "../config";
import classes from "./Dashboard.module.css";
import { useEtherBalance, useEthers } from "@usedapp/core";
import useTokenBalance from "../hooks/read/useTokenBalance";
import useTokenAllowance from "../hooks/read/useTokenAllowance";
import useApproveToken from "../hooks/write/useApproveToken";
import FileIcon from "./../assets/svg/file.svg";
import logo from "./../assets/images/logo2.png";
import axios from "axios";

import useStake from "../hooks/write/StashHouse/useStake";
import useTotalReferralsRewards from "../hooks/read/StashHouse/useTotalReferralsRewards";
import useAvailableReferralsRewards from "../hooks/read/StashHouse/useAvailableReferralsRewards";
import useCompoundReferrals from "../hooks/write/StashHouse/useCompoundReferrals";
import useClaimReferrals from "../hooks/write/StashHouse/useClaimReferrals";
import useStaking from "../hooks/read/StashHouse/useStaking";
import useAvailableRewards from "../hooks/read/StashHouse/useAvailableRewards";
import useCompoundRewards from "../hooks/write/StashHouse/useCompoundRewards";
import useClaimRewards from "../hooks/write/StashHouse/useClaimRewards";
import StakeCardERC20 from "../components/Garden/StakeCardERC20";
import StakeCard from "../components/Garden/StakeCard";
import useTotalBurnt from "../hooks/read/StashHouse/useTotalBurnt";
import { useSearchParams } from "react-router-dom";
import { isAddress } from "ethers/lib/utils";
import dripX from "../assets/dripx.mp4";

const toastOptions = {
  style: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "999rem",
    background: "#222222",
    border: "1px solid #282828",
    color: "#fff",
  },
};

const Account = () => {
  const [searchParams] = useSearchParams();
  const referrer = searchParams.get("ref");

  const totalBurntX = useTotalBurnt(
    GARDEN_TOKENS[1].contract,
    GARDEN_TOKENS[1].decimals
  );

  const [wdripPrice, setWdripPrice] = useState(0);

  const [priceX, setPriceX] = useState(0);

  const totalBurnt = useTotalBurnt(
    GARDEN_TOKENS[2].contract,
    GARDEN_TOKENS[2].decimals
  );

  const [price, setPrice] = useState(0);

  useEffect(() => {
    let interval = setInterval(async () => {
      let req = await axios.get(
        `https://api.dexscreener.com/latest/dex/tokens/${GARDEN_TOKENS[2].token}`
      );
      setPrice(req.data.pairs[0].priceUsd);
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    let interval = setInterval(async () => {
      fetch(
        "https://api.dexscreener.com/latest/dex/pairs/bsc/0x41e3149918f8EFeE8Ef6f47CF45D4CE580F837cB"
      )
        .then((res) => res.json())
        .then((data) => {
          setWdripPrice(data.pairs[0].priceUsd);
        });
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    let interval = setInterval(async () => {
      fetch(
        `https://api.dexscreener.com/latest/dex/tokens/${GARDEN_TOKENS[1].token}`
      )
        .then((res) => res.json())
        .then((data) => {
          const pair = data.pairs.filter(
            (el) => el.quoteToken.symbol == "WDRIP"
          )[0];
          setPriceX((pair.priceNative ?? 0) * (wdripPrice ?? 0));
        });
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, [wdripPrice]);

  return (
    <>
      <div className="w-full py-20 relative flex flex-col justify-center items-center -z-10 gap-8 mt-4 lg:mt-0">
        <h1
          className={`${classes.heading} font-bold uppercase text-4xl md:text-6xl text-white relative`}
        >
          <img alt="logo" src={logo} className="h-60" />
        </h1>
        {/* <span className="text-black opacity-70 -mt-6">
          Deposit Fee is used to burn DRIP
        </span> */}
        <a
          href="https://twitter.com/dripxwin"
          target="_blank"
          rel="noreferrer"
          className="w-[450px] max-w-full bg-black bg-opacity-50 rounded-2xl drop-shadow-xl p-4 border-4 border-dashed border-pinky shadow-[0_0_15px_5px_rgba(202,138,4,1)] shadow-pinky"
        >
          <video
            autoPlay
            loop
            muted
            className="w-full rounded-2xl drop-shadow-xl"
          >
            <source src={dripX} type="video/mp4" />
          </video>
        </a>
        <div className="flex gap-4 w-full flex-col lg:flex-row">
          <div className="w-full lg:w-1/2 bg-black bg-opacity-50 rounded-2xl drop-shadow-xl p-4 border-4 border-dashed border-pinky shadow-[0_0_15px_5px_rgba(202,138,4,1)] shadow-pinky">
            <div
              className={`bg-black bg-opacity-80 rounded-2xl drop-shadow-xl relative text-white text-center flex flex-col w-full p-6`}
            >
              <span className="opacity-50">TOTAL BURNT</span>
              <span className="text-pinky text-3xl">
                {totalBurnt.toFixed(2)} {GARDEN_TOKENS[2].symbol}
              </span>
              <span className="text-pinky opacity-50 text-2xl">
                ${(totalBurnt * price).toFixed(2)}
              </span>
            </div>
          </div>
          <div className="w-full lg:w-1/2 bg-black bg-opacity-50 rounded-2xl drop-shadow-xl p-4 border-4 border-dashed border-pinky shadow-[0_0_15px_5px_rgba(202,138,4,1)] shadow-pinky">
            <div
              className={`bg-black bg-opacity-80 rounded-2xl drop-shadow-xl relative text-white text-center flex flex-col w-full p-6`}
            >
              <span className="opacity-50">TOTAL BURNT</span>
              <span className="text-pinky text-3xl">
                {totalBurntX.toFixed(2)} {GARDEN_TOKENS[1].symbol}
              </span>
              <span className="text-pinky opacity-50 text-2xl">
                ${(totalBurntX * priceX).toFixed(2)}
              </span>
            </div>
          </div>
        </div>
        {referrer && isAddress(referrer) && (
          <div className="w-full lg:w-1/2 truncate bg-pinky bg-opacity-70 border-pinky border-2 text-white p-4 drop-shadow-xl rounded-2xl">
            You were referred by{" "}
            <a
              target="_blank"
              rel="noreferrer"
              className="border-b border-white hover:opacity-70 transition-all"
              href={`https://bscscan.com/address/${referrer}`}
            >
              {referrer}
            </a>
            .
          </div>
        )}
        <div className="grid w-full grid-cols-1 md:grid-cols-3 gap-4">
          {/* <StakeCard
            name={GARDEN_TOKENS[0].name}
            contract={GARDEN_TOKENS[0].contract}
            symbol={GARDEN_TOKENS[0].symbol}
          /> */}
          <StakeCardERC20
            name={GARDEN_TOKENS[1].name}
            contract={GARDEN_TOKENS[1].contract}
            token={GARDEN_TOKENS[1].token}
            symbol={GARDEN_TOKENS[1].symbol}
            decimals={GARDEN_TOKENS[1].decimals ?? 18}
            getUrl={GARDEN_TOKENS[1].getUrl}
          />
          {/* <StakeCardERC20
            name={GARDEN_TOKENS[2].name}
            contract={GARDEN_TOKENS[2].contract}
            token={GARDEN_TOKENS[2].token}
            symbol={GARDEN_TOKENS[2].symbol}
            decimals={GARDEN_TOKENS[2].decimals ?? 18}
            getUrl={GARDEN_TOKENS[2].getUrl}
          /> */}
          <StakeCardERC20
            name={GARDEN_TOKENS[3].name}
            contract={GARDEN_TOKENS[3].contract}
            token={GARDEN_TOKENS[3].token}
            symbol={GARDEN_TOKENS[3].symbol}
            decimals={GARDEN_TOKENS[3].decimals ?? 18}
            getUrl={GARDEN_TOKENS[3].getUrl}
          />
        </div>
      </div>
    </>
  );
};
export default Account;
