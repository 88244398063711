import { useState, useEffect } from "react";
import {
  Token,
  Fetcher,
  Trade,
  Route,
  TokenAmount,
  TradeType,
  Percent,
  WETH,
} from "@pancakeswap-libs/sdk-v2";
import { toast } from "react-hot-toast";

import { constants, ethers, utils } from "ethers";
import { CHAIN_ID, TOKEN_ADDRESS, DEX_ADDRESS } from "../config";
import classes from "./Dashboard.module.css";
import { useEtherBalance, useEthers } from "@usedapp/core";
import useTokenBalance from "../hooks/read/useTokenBalance";
import useTokenAllowance from "../hooks/read/useTokenAllowance";
import useApproveToken from "../hooks/write/useApproveToken";
import useSwapTokens from "../hooks/write/useSwapTokens";

import swapIcon from "../assets/svg/swap.svg";
import useSwapWETH from "../hooks/write/useSwapWETH";
import useSwapTokenToWETH from "../hooks/write/useSwapTokenToWETH";

let route;
let pair;
let PDRIP = new Token(CHAIN_ID, TOKEN_ADDRESS, 18);

const toastOptions = {
  style: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "999rem",
    background: "#222222",
    border: "1px solid #282828",
    color: "#fff",
  },
};

const Account = () => {
  const { account, library } = useEthers();
  const dexAllowance = useTokenAllowance(TOKEN_ADDRESS, account, DEX_ADDRESS);
  const tokenBalance = useTokenBalance(TOKEN_ADDRESS, account);
  const bnbBalance = useEtherBalance(account);

  const approveToken = useApproveToken(TOKEN_ADDRESS);
  const swapTokens = useSwapTokens();
  const swapWETH = useSwapWETH();
  const swapTokensToWETH = useSwapTokenToWETH();

  const [inputAmount, setInputAmount] = useState();
  const [outputAmount, setOutputAmount] = useState();
  const [inputToken, setInputToken] = useState("PLS");
  const [outputToken, setOutputToken] = useState("PDRIP");
  const [minimumReceived, setMinimumReceived] = useState(0.0);
  const [touched, setTouched] = useState("untouched");

  const [rest, setRest] = useState();

  useEffect(() => {
    const fetchPairs = async () => {
      let rest_ = [await Fetcher.fetchPairData(PDRIP, WETH[CHAIN_ID], library)];
      setRest(rest_);
    };
    fetchPairs();
  }, [library]);

  useEffect(() => {
    if (account) pair = rest;
  }, [account, rest]);

  const swap = () => {
    if (
      inputAmount == 0 ||
      outputAmount == 0 ||
      isNaN(inputAmount) ||
      isNaN(outputAmount)
    ) {
      //toastError({message: 'Invalid trade values'})
      return;
    }
    pair = rest;
    if (pair == null) return 0;
    route = new Route(pair, inputToken == "PLS" ? WETH[CHAIN_ID] : PDRIP);
    let trade = new Trade(
      route,
      new TokenAmount(
        inputToken == "PLS" ? WETH[CHAIN_ID] : PDRIP,
        inputAmount * 1e18
      ),
      TradeType.EXACT_INPUT
    );
    const slippageTolerance = new Percent(1500, "10000");
    const amountOutMin = trade.minimumAmountOut(slippageTolerance).raw;
    const path = Array.from(route.path, (el) => el.address);
    const to = account;
    const deadline = Math.floor(Date.now() / 1000) + 60 * 20;
    const value = trade.inputAmount.raw;

    doSwap(String(amountOutMin), path, to, deadline, String(value));
  };

  const getQuote = (
    val,
    input = WETH[CHAIN_ID],
    type = TradeType.EXACT_INPUT
  ) => {
    pair = rest;
    if (pair == null) return 0;
    route = new Route(pair, input);
    let trade = new Trade(
      route,
      new TokenAmount(input, utils.parseEther("" + val)),
      type
    );
    const slippageTolerance = new Percent(1500, "10000");
    const amountOutMin = trade.minimumAmountOut(slippageTolerance).raw;

    return [trade.executionPrice.toSignificant(6), String(amountOutMin)];
  };

  const handlePriceChange = (field, value) => {
    if (value == undefined || value == "") {
      setInputAmount("");
      setOutputAmount("");
      return;
    }
    if (value > 0) {
      pair = rest;
      let quote = getQuote(
        value,
        inputToken == "PLS" ? WETH[CHAIN_ID] : PDRIP,
        TradeType.EXACT_INPUT
      );
      if (field == "output") {
        setTouched("output");
        setOutputAmount(value);
        value > 0 ? setInputAmount(value / quote[0]) : setInputAmount("");
      } else if (field == "input") {
        setTouched("input");
        setInputAmount(value);
        value > 0 ? setOutputAmount(quote[0] * value) : setOutputAmount("");
      }
      value > 0 ? setMinimumReceived(quote[1]) : setMinimumReceived(0);
    } else {
      if (field == "output") {
        setOutputAmount(value);
        setInputAmount("0");
      } else if (field == "input") {
        setInputAmount(value);
        setOutputAmount("0");
      }
      return;
    }
  };

  const handleSwitch = () => {
    let temp = inputToken;
    setInputToken(outputToken);
    setOutputToken(temp);
    temp = outputAmount;
    setOutputAmount(inputAmount);
    setInputAmount(temp);
    setTouched((prev) => (prev == "input" ? "output" : "input"));
  };

  useEffect(() => {
    let interval = setInterval(() => {
      if (touched != "untouched")
        handlePriceChange(
          touched,
          touched == "input" ? inputAmount : outputAmount
        );
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, [touched, inputAmount, outputAmount]);

  const doSwap = async (amountOutMin, path, to, deadline, value) => {
    if (outputToken == "PLS") {
      swapTokensToWETH.send(value, amountOutMin, path, to, deadline);
    } else {
      swapWETH.send(amountOutMin, path, to, deadline, { value });
    }
  };

  const handleClick = () => {
    if (inputToken == "PDRIP" && +dexAllowance < +inputAmount) {
      approveToken.send(DEX_ADDRESS, constants.MaxUint256);
    } else {
      swap();
    }
  };

  const resetFields = () => {
    setInputAmount("");
    setOutputAmount("");
  };

  const handleMax = () => {
    if (inputToken == "PLS") {
      handlePriceChange(
        "input",
        parseFloat(utils.formatEther(bnbBalance)).toFixed(3) - 0.001
      );
    } else {
      handlePriceChange("input", tokenBalance.toFixed(3) - 0.001);
    }
  };

  useEffect(() => {
    if (
      swapTokensToWETH.state.status == "Exception" ||
      swapTokensToWETH.state.status == "Fail"
    ) {
      toast.error(
        swapTokensToWETH.state.errorMessage
          .split(":")
          [swapTokensToWETH.state.errorMessage.split(":").length - 1].trim(),
        toastOptions
      );
    } else if (swapTokensToWETH.state.status == "Success") {
      toast.success("Successfully swapped!", toastOptions);
    }
  }, [swapTokensToWETH.state]);

  useEffect(() => {
    if (
      swapTokens.state.status == "Exception" ||
      swapTokens.state.status == "Fail"
    ) {
      toast.error(
        swapTokens.state.errorMessage
          .split(":")
          [swapTokens.state.errorMessage.split(":").length - 1].trim(),
        toastOptions
      );
    } else if (swapTokens.state.status == "Success") {
      toast.success("Successfully swapped!", toastOptions);
    }
  }, [swapTokens.state]);

  useEffect(() => {
    if (
      swapWETH.state.status == "Exception" ||
      swapWETH.state.status == "Fail"
    ) {
      toast.error(
        swapWETH.state.errorMessage
          .split(":")
          [swapWETH.state.errorMessage.split(":").length - 1].trim(),
        toastOptions
      );
    } else if (swapWETH.state.status == "Success") {
      toast.success("Successfully swapped!", toastOptions);
    }
  }, [swapWETH.state]);

  useEffect(() => {
    if (
      approveToken.state.status == "Exception" ||
      approveToken.state.status == "Fail"
    ) {
      toast.error(
        approveToken.state.errorMessage
          .split(":")
          [approveToken.state.errorMessage.split(":").length - 1].trim(),
        toastOptions
      );
    } else if (approveToken.state.status == "Success") {
      toast.success("Successfully approved PDRIP!", toastOptions);
    }
  }, [approveToken.state]);

  return (
    <div className="w-full h-screen relative flex flex-col justify-center items-center -z-10 gap-8 mt-8 lg:mt-0">
      <h1
        className={`${classes.heading} font-bold uppercase text-4xl md:text-6xl text-white relative `}
      >
        swap
      </h1>
      <div className="bg-black bg-opacity-50 p-4 rounded-2xl drop-shadow-xl w-full md:w-4/5 2xl:w-1/2 flex justify-center gap-8">
        <div
          className={`rounded-2xl drop-shadow-xl relative bg-black bg-opacity-80 text-white text-center flex flex-col w-full p-6`}
        >
          <div className="flex w-full flex-col">
            <div className="flex justify-between">
              <div className="opacity-50">From</div>
              <div onClick={handleMax}>
                <span className="opacity-50">Max</span>
                <span className="text-pinky ml-2 cursor-pointer">
                  {inputToken == "PDRIP" && tokenBalance.toFixed(3)}
                  {inputToken == "PLS" &&
                    (bnbBalance
                      ? parseFloat(utils.formatEther(bnbBalance)).toFixed(3)
                      : (0).toFixed(3))}
                </span>
              </div>
            </div>
            <div className="flex items-center justify-center border-b border-pinky">
              <input
                className=" h-full z-20 w-full flex-1 outline-none  bg-transparent py-3 opacity-100 disabled:cursor-not-allowed"
                value={inputAmount}
                onPaste={(e) => handlePriceChange("input", e.target.value)}
                onChange={(e) => handlePriceChange("input", e.target.value)}
              />
              {outputToken == "PDRIP" ? (
                <select
                  className="bg-transparent outline-none"
                  value={inputToken}
                  onChange={(e) => {
                    setInputToken(e.target.value);
                    resetFields();
                  }}
                >
                  <option className="bg-black text-white" value="PLS">
                    PLS
                  </option>
                </select>
              ) : (
                <div>PDRIP</div>
              )}
            </div>
          </div>
          <div className="flex justify-center items-center w-full py-6">
            <div
              className="w-12 h-12 bg-pinky rounded-full cursor-pointer flex justify-center items-center"
              onClick={handleSwitch}
            >
              <img
                src={swapIcon}
                className={`transform transition-all duration-300 ${
                  inputToken == "PDRIP" ? "rotate-180" : "rotate-0"
                }`}
              />
            </div>
          </div>
          <div className="flex w-full flex-col">
            <div className="flex justify-between">
              <div className="opacity-50">To</div>
              <div>
                <span className="opacity-50">Balance</span>
                <span className="text-pinky ml-2 cursor-pointer">
                  {outputToken == "PDRIP" && tokenBalance.toFixed(3)}
                  {outputToken == "PLS" &&
                    (bnbBalance
                      ? parseFloat(utils.formatEther(bnbBalance)).toFixed(3)
                      : (0).toFixed(3))}
                </span>
              </div>
            </div>
            <div className="flex items-center justify-center border-b border-pinky">
              <input
                className=" h-full z-20 w-full flex-1 outline-none  bg-transparent py-3 opacity-100 disabled:cursor-not-allowed"
                value={outputAmount}
                onPaste={(e) => handlePriceChange("output", e.target.value)}
                onChange={(e) => handlePriceChange("output", e.target.value)}
              />
              {outputToken != "PDRIP" ? (
                <select
                  className="bg-transparent outline-none"
                  value={outputToken}
                  onChange={(e) => {
                    setOutputToken(e.target.value);
                    resetFields();
                  }}
                >
                  <option className="bg-black text-white" value="PLS">
                    PLS
                  </option>
                </select>
              ) : (
                <div>PDRIP</div>
              )}
            </div>
          </div>
          <div className="flex flex-col w-full text-sm py-6">
            <div className="w-full flex justify-between">
              <p className="opacity-50">Price</p>
              {outputToken == "PDRIP" && (
                <p>
                  {pair != null
                    ? getQuote(inputAmount > 0 ? inputAmount : 1)[0]
                    : 0}{" "}
                  PDRIP per {inputToken}
                </p>
              )}
              {outputToken != "PDRIP" && (
                <p>
                  {pair != null
                    ? getQuote(inputAmount > 0 ? inputAmount : 1)[0]
                    : 0}{" "}
                  {outputToken} per PDRIP
                </p>
              )}
            </div>
            <div className="w-full flex justify-between">
              <p className="opacity-50">Auto Slippage</p>
              <p>Active</p>
            </div>
            <div className="w-full flex justify-between">
              <p className="opacity-50">Minimum Received</p>
              <p>
                {(parseFloat(minimumReceived) / 1e18).toFixed(3)}{" "}
                {outputToken == "PDRIP" ? "PDRIP" : "PLS"}
              </p>
            </div>
          </div>
          <div className="flex justify-center items-center w-full">
            <button
              className={`bg-pinky border-2 border-pinky hover:bg-opacity-40 drop-shadow-xl rounded-lg text-white transition-all font-medium w-3/4 py-2 relative z-10`}
              onClick={handleClick}
            >
              {(inputToken == "PDRIP" && +dexAllowance >= +inputAmount) ||
              inputToken == "PLS"
                ? "Exchange"
                : "Approve"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;
