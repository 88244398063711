// export const TOKEN_ADDRESS = "0xef7496b17B4c4a8770404807628f83f7FaE8cc06"
// export const DEX_ADDRESS = "0x0b925688872520B8FF90e4b7DC912dA252460f66"
// export const NFT_ADDRESS = "0xc3f0c223afdcb3d87e701ec60c2ba771d2ba6fcc"
// export const MANAGER_ADDRESS = "0x846e181f6A3112d2Fd69795975135Ca1fE09180B"

// export const DEX_ADDRESS = "0x636f6407B90661b73b1C0F7e24F4C79f624d0738";
// export const XDRIP_ADDRESS = "0xeAAdF15e911F8621F51b37565E06c3E9fcf0E5F2";
// export const MARKETING_WALLET = "0x180De5750C5Ca8d693B428edE02eaf48ccab85EA";
// export const POOL_WALLET = "0xaa6Be2C921F4a67D078d21868b2a6E786f58976D";
// export const TOKEN_ADDRESS = "0xcB125683A6D8011805c5954BD6B3B471142a4704";
// export const MANAGER_ADDRESS = "0xf00eD5a513C9a73df8A9A27C6d13e30F292EE8B6";
// export const NFT_ADDRESS = "0x59884C8f4dFD72733e03224c57FcdD4b7d07aBD2";
// export const TEAMS_ADDRESS = "0xaa1bAFF0f1A967f732aA6B80e2A47e14598027e8";
// export const STASH_HOUSE_ADDRESS = "0xfB2d65f3F2e6C3E44453083e04Cb093D686F4F28";

export const DEX_ADDRESS = "0x165C3410fC91EF562C50559f7d2289fEbed552d9";
export const XDRIP_ADDRESS = "0x92d86bF53727B40D731b77E4Aa4BF57EA5a22a95";
export const MARKETING_WALLET = "0x1e729c69aA233DD9f79c9132375AC4ECBD4c40d4";
export const POOL_WALLET = "0xBa46e7abF4979B3FF06329cF8A2257D1B3Be109F";
export const TOKEN_ADDRESS = "0x20f663CEa80FaCE82ACDFA3aAE6862d246cE0333";
export const MANAGER_ADDRESS = "0xb4EfF0FaC4115A4eAA61bFdb97564BC24ed0129b";
export const NFT_ADDRESS = "0xC2aE79231f6277503D4c4167C6fbb60b6421246B";
export const TEAMS_ADDRESS = "0x0dad5F29f02F7F080Dbe02B5ac76E0cDE13334Ed";
export const STASH_HOUSE_ADDRESS = "0x6C508DF3aa684Dd707cE084651A24fb903C8cae0";
export const NATIVE_FARM_ADDRESS = "0x4FC5fa126F7A748F2c3D4e15907955Ed7405B6DF";

export const NATIVE_FARM_TOKENS = {
  "0x25D240831a9c0CB981506538E810d32487D291Af": "pDRIP/PLS",
  "0x5EF7AaC0DE4F2012CB36730Da140025B113FAdA4": "pDAI/ATROPA",
};

export const GARDEN_TOKENS = [
  {
    name: "BNB",
    token: undefined,
    contract: "0x6C508DF3aa684Dd707cE084651A24fb903C8cae0",
    isSwappable: false,
    symbol: "BNB",
  },
  {
    name: "DRIPX",
    token: "0xeCbcE2c13d3A1248deBfD23DCc0558b495916756",
    contract: "0x613E4e55e257c76Bd510343F10F58f468D863e0f",
    isSwappable: false,
    symbol: "DRIPX",
    getUrl:
      "https://pancakeswap.finance/swap?outputCurrency=0xeCbcE2c13d3A1248deBfD23DCc0558b495916756&inputCurrency=0xf30224eb7104aca47235beb3362e331ece70616a",
  },
  {
    name: "DRIP",
    token: "0x20f663CEa80FaCE82ACDFA3aAE6862d246cE0333",
    contract: "0x19593aF864D255726f26324cd2ab868ECeea796e",
    isSwappable: false,
    symbol: "DRIP",
    getUrl:
      "https://pancakeswap.finance/swap?outputCurrency=0x20f663CEa80FaCE82ACDFA3aAE6862d246cE0333",
  },
  {
    name: "WDRIP",
    token: "0xf30224eb7104aca47235beb3362e331ece70616a",
    contract: "0x105B9218BacF83b114F41271f075b8c9FdCD987E",
    isSwappable: false,
    symbol: "WDRIP",
    getUrl: "https://aidrip.xyz",
  },
  {
    name: "PulseX",
    token: "0x95B303987A60C71504D99Aa1b13B4DA07b0790ab",
    contract: "0xC3653A4E7be67BBca196A23769F6EDD5328C8EA1",
    isSwappable: true,
    symbol: "PLSX",
  },
  {
    name: "HEX",
    token: "0x2b591e99afE9f32eAA6214f7B7629768c40Eeb39",
    contract: "0xf95139e1580312a90c58ce7Fdef4f2fd18CD45eC",
    isSwappable: true,
    symbol: "HEX",
    decimals: 8,
  },
  {
    name: "pwBTC",
    token: "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599",
    contract: "0x594ec0592E12C7f157a614F054885EdB1D4be987",
    isSwappable: true,
    symbol: "WBTC",
    decimals: 8,
  },
  {
    name: "ATROPA",
    token: "0xcc78a0acdf847a2c1714d2a925bb4477df5d48a6",
    contract: "0x84705111aDD3f324759e130268e8Aa155CD405F8",
    isSwappable: true,
    symbol: "ATR",
  },
  {
    name: "TEDDY BEAR",
    token: "0xd6c31bA0754C4383A41c0e9DF042C62b5e918f6d",
    contract: "0xFD4B591B825147c801118eF4f1B37B6943aA74BF",
    isSwappable: true,
    symbol: "BEAR",
  },
  {
    name: "USDC from ETH",
    token: "0x15d38573d2feeb82e7ad5187ab8c1d52810b1f07",
    contract: "0xbB38533c6144Fc5303Cdb192782B2f8985790d5f",
    isSwappable: true,
    symbol: "USDC",
    decimals: 6,
  },
];

export const PLS_ADDRESS = "";

export const JSON_RPC = "https://bsc-dataseed2.ninicoin.io";
export const CHAIN_ID = 369;
export const CHAIN_NAME = "Binance Smart Chain";
export const EXPLORER = "https://bscscan.com/";

export const NATIVE_CURRENCY_NAME = "Binance";
export const NATIVE_CURRENCY_SYMBOL = "BNB";
export const NATIVE_CURRENCY_DECIMALS = 18;

export const TOKEN_SYMBOL = "PDRIP";
export const TOKEN_DECIMALS = 18;
