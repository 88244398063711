import { useState, useEffect, useRef } from "react";
import { toast } from "react-hot-toast";
import Countdown from "react-countdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

import { constants, utils } from "ethers";
import { useEtherBalance, useEthers } from "@usedapp/core";
import useTokenBalance from "../../hooks/read/useTokenBalance";
import useTokenAllowance from "../../hooks/read/useTokenAllowance";
import useApproveToken from "../../hooks/write/useApproveToken";
import FileIcon from "./../../assets/svg/file.svg";

import useStake from "../../hooks/write/StashHouse/ERC20/useStake";
import useTotalReferralsRewards from "../../hooks/read/StashHouse/useTotalReferralsRewards";
import useStaking from "../../hooks/read/StashHouse/useStaking";
import useAvailableRewards from "../../hooks/read/StashHouse/useAvailableRewards";
import useCompoundRewards from "../../hooks/write/StashHouse/useCompoundRewards";
import useClaimRewards from "../../hooks/write/StashHouse/useClaimRewards";
import useTotalBurnt from "../../hooks/read/StashHouse/useTotalBurnt";

const toastOptions = {
  style: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "999rem",
    background: "#222222",
    border: "1px solid #282828",
    color: "#fff",
  },
};

const StakeCard = ({ name, contract, token, symbol, decimals, getUrl }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { account } = useEthers();

  const [amount, setAmount] = useState();

  const gardenBalance = useTokenBalance(token, contract, decimals);
  const balance = useTokenBalance(token, account, decimals);
  const allowance = useTokenAllowance(token, account, contract, decimals);

  const staking = useStaking(contract);
  const availableRewards = useAvailableRewards(contract, decimals);

  let approveToken = useApproveToken(token);
  let stake = useStake(contract);
  let compoundRewards = useCompoundRewards(contract);
  let claimRewards = useClaimRewards(contract);

  let timer = useRef();

  useEffect(() => {
    if (staking.lastAction != 0) timer.current.getApi().start();
  }, [staking]);

  const handleAction = () => {
    if (!allowance || +allowance < +amount) {
      approveToken.send(contract, constants.MaxUint256);
    } else {
      stake.send(utils.parseUnits(amount + "", decimals));
    }
  };

  useEffect(() => {
    if (
      approveToken.state.status == "Exception" ||
      approveToken.state.status == "Fail"
    ) {
      toast.error(
        approveToken.state.errorMessage
          .split(":")
          [approveToken.state.errorMessage.split(":").length - 1].trim(),
        toastOptions
      );
    } else if (approveToken.state.status == "Success") {
      toast.success("Successfully approved!", toastOptions);
    }
  }, [approveToken.state]);

  useEffect(() => {
    if (stake.state.status == "Exception" || stake.state.status == "Fail") {
      toast.error(
        stake.state.errorMessage
          .split(":")
          [stake.state.errorMessage.split(":").length - 1].trim(),
        toastOptions
      );
    } else if (stake.state.status == "Success") {
      toast.success("Successfully staked!", toastOptions);
    }
  }, [stake.state]);

  useEffect(() => {
    if (
      compoundRewards.state.status == "Exception" ||
      compoundRewards.state.status == "Fail"
    ) {
      toast.error(
        compoundRewards.state.errorMessage
          .split(":")
          [compoundRewards.state.errorMessage.split(":").length - 1].trim(),
        toastOptions
      );
    } else if (compoundRewards.state.status == "Success") {
      toast.success("Successfully compounded!", toastOptions);
    }
  }, [compoundRewards.state]);

  useEffect(() => {
    if (
      claimRewards.state.status == "Exception" ||
      claimRewards.state.status == "Fail"
    ) {
      toast.error(
        claimRewards.state.errorMessage
          .split(":")
          [claimRewards.state.errorMessage.split(":").length - 1].trim(),
        toastOptions
      );
    } else if (claimRewards.state.status == "Success") {
      toast.success("Successfully claimed!", toastOptions);
    }
  }, [claimRewards.state]);

  return (
    <div className="w-full flex flex-col gap-8 items-center">
      <div className="w-full h-full p-4 bg-black bg-opacity-50 rounded-2xl drop-shadow-xl flex justify-center gap-8">
        <div
          className={`bg-black bg-opacity-80 rounded-2xl drop-shadow-xl relative text-white text-center flex flex-col justify-between w-full p-6`}
        >
          <div>
            <div className="flex w-full flex-col">
              <div className="flex flex-col items-center justify-center">
                <div className="flex justify-center gap-2 items-center mb-4">
                  <img
                    className="w-12 h-12 p-1 rounded-full"
                    src={`/tokens/${token}.png`}
                    alt={name}
                  />
                  <span className="font-semibold text-xl">{name}</span>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`https://bscscan.com/address/${contract}/`}
                    className="opacity-50 hover:opacity-100 transition-all"
                  >
                    <img src={FileIcon} className="invert " alt="contract" />
                  </a>
                </div>
                <a
                  href={getUrl}
                  target="_blank"
                  rel="noreferrer"
                  className="text-pinky flex gap-2 justify-center items-center w-fit"
                >
                  Get {symbol}
                  <FontAwesomeIcon icon={faExternalLinkAlt} />
                </a>
              </div>
              <div className="flex justify-between">
                <div className="opacity-50">Stake</div>
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    setAmount(balance.toFixed(5) - 0.00001);
                  }}
                >
                  <span className="opacity-50">Max</span>
                  <span className="text-pinky ml-2">
                    {balance ? balance.toFixed(2) : 0}
                  </span>
                  {/* <span className="opacity-50">PLS</span> */}
                </div>
              </div>
              <div className="flex items-center justify-center border-b border-pinky">
                <input
                  className="h-full z-20 w-full flex-1 outline-none bg-transparent py-3 opacity-100 disabled:cursor-not-allowed"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </div>
            </div>
            <div className="flex flex-col w-full text-sm pt-6">
              <div className="w-full flex justify-between">
                <p className="opacity-50">Daily ROI</p>
                <p>1.50%</p>
              </div>
              <div className="w-full flex justify-between">
                <p className="opacity-50">TVL</p>
                <p>
                  {gardenBalance ? gardenBalance.toFixed(2) : 0} {symbol}
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-center items-center w-full pt-6">
              <button
                className={`bg-pinky border-2 border-pinky hover:bg-opacity-40 drop-shadow-xl rounded-lg text-white transition-all font-medium w-3/4 py-2  relative z-10`}
                onClick={handleAction}
              >
                {!allowance || +allowance < +amount ? "Approve" : "Stake"}
              </button>
            </div>
            <div className="flex w-full justify-between mt-4">
              <div className="flex w-full flex-col items-start">
                <p className="opacity-50">Total Staked</p>
                <p className="text-lg">
                  {(+utils.formatUnits(staking.amount, decimals)).toFixed(3)}{" "}
                  {symbol}
                </p>
              </div>
              <div className="flex w-full flex-col items-end">
                <p className="opacity-50">Available</p>
                <p className="text-lg">
                  {availableRewards.toFixed(5)} {symbol}
                </p>
              </div>
            </div>
            <div className="flex flex-col w-full text-sm pt-6">
              <div className="w-full flex justify-between">
                <p className="opacity-50">Daily ROI</p>
                <p>
                  {(
                    utils.formatUnits(staking.amount, decimals) * 0.015
                  ).toFixed(5)}{" "}
                  {symbol}
                </p>
              </div>
              <div className="w-full flex justify-between">
                <p className="opacity-50">Available Claims</p>
                <p>
                  {Math.floor(staking.nOfReinvestments / 3) - staking.nOfClaims}
                </p>
              </div>
              <div className="w-full flex justify-between">
                <p className="opacity-50">Total Deposited</p>
                <p>
                  {(+utils.formatUnits(
                    staking.initialDeposit,
                    decimals
                  )).toFixed(3)}{" "}
                  {symbol}
                </p>
              </div>
              <div className="w-full flex justify-between">
                <p className="opacity-50">Total Compounded</p>
                <p>
                  {(+utils.formatUnits(
                    staking.totalCompounded,
                    decimals
                  )).toFixed(3)}{" "}
                  {symbol}
                </p>
              </div>
              <div className="w-full flex justify-between">
                <p className="opacity-50">Total Claimed</p>
                <p>
                  {(+utils.formatUnits(staking.totalClaimed, decimals)).toFixed(
                    3
                  )}{" "}
                  {symbol}
                </p>
              </div>
              <div className="w-full flex justify-between">
                <p className="opacity-50">Cutoff in</p>
                <p>
                  {staking.lastAction == 0 ? (
                    "--"
                  ) : (
                    <Countdown
                      ref={timer}
                      date={(+staking.lastAction + 86400 * 2) * 1000}
                    >
                      <p className="text-red-400">00:00:00:00</p>
                    </Countdown>
                  )}
                </p>
              </div>
              <div className="w-full flex justify-between">
                <p className="opacity-50">Last Action</p>
                <p>
                  {staking.lastTimeableAction == 0
                    ? "--"
                    : new Date(
                        staking.lastTimeableAction * 1000
                      ).toLocaleString()}
                </p>
              </div>
            </div>
          </div>
          <div className="flex justify-center items-center w-full pt-6 gap-4">
            <div className="flex flex-col w-3/4">
              <p className="opacity-50 text-right">5% tax</p>
              <button
                className={`bg-pinky border-2 border-pinky hover:bg-opacity-40 drop-shadow-xl rounded-lg text-white transition-all font-medium w-full py-2  relative z-10`}
                onClick={() => compoundRewards.send()}
              >
                Compound
              </button>
            </div>
            <div className="flex flex-col w-3/4">
              <p className="opacity-50 text-right">10% tax</p>
              <button
                className={`bg-pinky border-2 border-pinky hover:bg-opacity-40 drop-shadow-xl rounded-lg text-white transition-all font-medium w-full py-2  relative z-10`}
                onClick={() => claimRewards.send()}
              >
                Claim
              </button>
            </div>
          </div>
          {/* <div
          onClick={() => setIsOpen((prev) => !prev)}
          className="flex gap-2 justify-center items-center mt-4 cursor-pointer"
        >
          {!isOpen ? (
            <>
              <FontAwesomeIcon icon={faChevronDown} /> Referrals
            </>
          ) : (
            <>
              <FontAwesomeIcon icon={faChevronUp} /> Hide
            </>
          )}
        </div>
        {isOpen && (
          <div className="flex flex-col w-full text-sm mt-4">
            <div className="w-full flex justify-between">
              <p className="opacity-50">Total Comission</p>
              <p>
                {totalReferralsRewards} {symbol}
              </p>
            </div>
            <div className="w-full flex justify-between">
              <p className="opacity-50">Claimed Comission</p>
              <p>
                {totalReferralsRewards - availableReferralsRewards} {symbol}
              </p>
            </div>
            <div className="w-full flex justify-between">
              <p className="opacity-50">Available Comission</p>
              <p>
                {availableReferralsRewards} {symbol}
              </p>
            </div>
            <div className="flex justify-center items-center w-full pt-6 gap-4">
              <div className="flex flex-col w-3/4">
                <p className="opacity-50 text-right">0% tax</p>
                <button
                  className={`bg-pinky border-2 border-pinky hover:bg-opacity-40 drop-shadow-xl rounded-lg text-white transition-all font-medium w-full py-2  relative z-10`}
                  onClick={() => compoundReferrals.send()}
                >
                  Compound
                </button>
              </div>
              <div className="flex flex-col w-3/4">
                <p className="opacity-50 text-right">30% tax</p>
                <button
                  className={`bg-pinky border-2 border-pinky hover:bg-opacity-40 drop-shadow-xl rounded-lg text-white transition-all font-medium w-full py-2  relative z-10`}
                  onClick={() => claimReferrals.send()}
                >
                  Claim
                </button>
              </div>
            </div>
          </div>
        )} */}
        </div>
      </div>
    </div>
  );
};

export default StakeCard;
