import { utils } from "ethers";
import { useCall } from "@usedapp/core";
import { Contract } from "@ethersproject/contracts";
import ERC20Interface from "../../contracts/erc20.json";
import { formatUnits } from "ethers/lib/utils";

const useTokenAllowance = (
  tokenAddress,
  ownerAddress,
  spenderAddress,
  decimals = 18
) => {
  const { value, error } = useCall(
    ownerAddress &&
      spenderAddress &&
      tokenAddress && {
        contract: new Contract(
          tokenAddress,
          new utils.Interface(ERC20Interface)
        ),
        method: "allowance",
        args: [ownerAddress, spenderAddress],
      }
  ) ?? { value: [0] };
  if (error) {
    console.error(error.message);
    return undefined;
  }
  return value?.[0] ? formatUnits(value?.[0], decimals) : 0;
};

export default useTokenAllowance;
