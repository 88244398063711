import classes from "./Header.module.css";
import Web3ModalButton from "./Web3ModalButton";
import pic from "../../assets/images/logo.svg";
import menu from "../../assets/svg/menu.svg";
import axios from "axios";
import { useEffect, useState } from "react";
import { useEthers } from "@usedapp/core";
import useTokenBalance from "../../hooks/read/useTokenBalance";
import { TOKEN_ADDRESS } from "../../config";

const Header = ({ setIsMenuOpen }) => {
  const { account } = useEthers();
  const balance = useTokenBalance(TOKEN_ADDRESS, account);
  const [price, setPrice] = useState(0);
  const [copiedText, setCopiedText] = useState("Invite");
  useEffect(() => {
    let interval = setInterval(async () => {
      let req = await axios.get(
        "https://api.dexscreener.com/latest/dex/tokens/0x20f663CEa80FaCE82ACDFA3aAE6862d246cE0333"
      );
      setPrice(req.data.pairs[0].priceUsd);
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    let timeout;
    if (copiedText == "Copied!")
      timeout = setTimeout(() => {
        setCopiedText("Invite");
      }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [copiedText]);

  return (
    <>
      <div
        className={`hidden lg:flex absolute bg-black bg-opacity-80 drop-shadow-xl rounded-lg text-white px-4 h-12 left-0 top-10 justify-center items-center`}
      >
        ${price}
      </div>
      <div className="flex gap-4 absolute right-0 top-10">
        {account && (
          <div
            className={` flex   bg-transparent border-2 border-pinky brightness-100 hover:bg-pinky drop-shadow-xl rounded-lg text-black hover:text-white transition-all px-4 h-12  justify-center items-center cursor-pointer gap-2`}
            onClick={() => {
              setCopiedText("Copied!");
              navigator.clipboard.writeText(
                `https://kitchensink.gg/?ref=${account}`
              );
            }}
          >
            {copiedText}
          </div>
        )}
        <div
          className={` flex  bg-transparent border-2 border-pinky brightness-100 hover:bg-pinky drop-shadow-xl rounded-lg text-black hover:text-white transition-all px-4 h-12 justify-center items-center cursor-pointer gap-2`}
        >
          {account && <p>{balance.toFixed(3)} $DRIP |</p>}
          <Web3ModalButton className="h-full" />
        </div>
      </div>
    </>
  );
};

export default Header;
