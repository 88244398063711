import { utils } from "ethers";
import { useContractFunction } from "@usedapp/core";
import { Contract } from "@ethersproject/contracts";
import STASH_HOUSE_ABI from "../../../contracts/stashhouse.json";
import { STASH_HOUSE_ADDRESS } from "../../../config";

const useCompoundRewards = (contractAddress = STASH_HOUSE_ADDRESS) => {
  const contract = new Contract(
    contractAddress,
    new utils.Interface(STASH_HOUSE_ABI)
  );
  const { state, send } = useContractFunction(contract, "compound", {
    transactionName: "Compound",
  });

  return { state, send };
};

export default useCompoundRewards;
