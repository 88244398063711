import { utils } from "ethers";
import { useCall, useEthers } from "@usedapp/core";
import { Contract } from "@ethersproject/contracts";
import STASH_HOUSE_ABI from "../../../contracts/stashhouseerc20.json";
import { STASH_HOUSE_ADDRESS } from "../../../config";

const useTotalBurnt = (
  contractAddress = STASH_HOUSE_ADDRESS,
  decimals = 18
) => {
  const { value, error } = useCall(
    contractAddress && {
      contract: new Contract(
        contractAddress,
        new utils.Interface(STASH_HOUSE_ABI)
      ),
      method: "totalBurnt",
      args: [],
    }
  ) ?? { value: [0] };
  if (error) {
    console.error(error.message);
    return undefined;
  }
  return parseFloat(utils.formatUnits(value?.[0], decimals));
};

export default useTotalBurnt;
