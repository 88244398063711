import { utils } from "ethers";
import { useContractFunction } from "@usedapp/core";
import { Contract } from "@ethersproject/contracts";
import GARDENERC20 from "../../../../contracts/stashhouseerc20.json";

const useStake = (contractAddress) => {
  const contract = new Contract(
    contractAddress,
    new utils.Interface(GARDENERC20)
  );
  const { state, send } = useContractFunction(contract, "deposit", {
    transactionName: "Stake",
  });

  return { state, send };
};

export default useStake;
