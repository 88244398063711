import { utils } from "ethers";
import { useContractFunction } from "@usedapp/core";
import { Contract } from "@ethersproject/contracts";
import ERC20Interface from "../../contracts/erc20.json";

const useApproveToken = (tokenAddress) => {
  const contract =
    tokenAddress &&
    new Contract(tokenAddress, new utils.Interface(ERC20Interface));
  const { state, send } = useContractFunction(contract, "approve", {
    transactionName: "Approving!",
  });

  return { state, send };
};

export default useApproveToken;
