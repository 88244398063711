import { utils } from "ethers";
import { useCall, useEthers } from "@usedapp/core";
import { Contract } from "@ethersproject/contracts";
import STASH_HOUSE_ABI from "../../../contracts/stashhouse.json";
import { STASH_HOUSE_ADDRESS } from "../../../config";

const useStaking = (contractAddress = STASH_HOUSE_ADDRESS) => {
  const { account } = useEthers();
  const { value, error } = useCall(
    contractAddress &&
      account && {
        contract: new Contract(
          contractAddress,
          new utils.Interface(STASH_HOUSE_ABI)
        ),
        method: "stakings",
        args: [account],
      }
  ) ?? {
    value: {
      amount: 0,
      lastAction: 0,
      lastTimeableAction: 0,
      initialDeposit: 0,
      nOfReinvestments: 0,
      nOfClaims: 0,
      totalCompounded: 0,
      totalClaimed: 0,
    },
  };
  if (error) {
    console.error(error.message);
    return undefined;
  }
  return value;
};

export default useStaking;
