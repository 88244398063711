import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { BSC, DAppProvider } from "@usedapp/core";
import { getDefaultProvider } from "ethers";
import { JSON_RPC } from "./config";

const config = {
  readOnlyChainId: BSC.chainId,
  readOnlyUrls: {
    [BSC.chainId]: getDefaultProvider(JSON_RPC),
  },
  pollingInterval: 10000,
  autoConnect: true,
};

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <DAppProvider config={config}>
        <App />
      </DAppProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
